import styled from 'styled-components';

const Container = styled.div`
  h1 {
    font-weight: 900;
    font-size: 22px;
    line-height: 1.45;
    text-transform: uppercase;
    text-align: center;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 36px;
    }
  }

  h2 {
    margin-top: 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.45;
    text-transform: uppercase;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 28px;
    }
  }

  h3 {
    margin-top: 32px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.45;
    text-transform: uppercase;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 18px;
    }
  }

  h4 {
    margin-top: 32px;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.45;
    text-transform: uppercase;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 16px;
    }
  }

  h5 {
    margin-top: 32px;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 300;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 34px;
      text-align: justify;
    }
  }

  h6 {
    margin-top: 150px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.45;
    text-transform: uppercase;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 22px;
      margin-top: 300px;
    }
  }

  p {
    margin-top: 32px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 300;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 22px;
      text-align: justify;
    }
  }

  ul,
  ol {
    margin-left: 40px;
    margin-top: 20px;
  }

  li {
    margin-top: 8px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 300;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 22px;
      text-align: justify;
    }
  }

  a {
    color: ${(props) => props.theme.colors.dirtOrange};
    font-weight: 600;
  }
`;

export default Container;
