// IMPORTANT TODO: phase out 'Header.tsx' and 'NewHeader.tsx'

import React, { FC } from 'react';
import styled from 'styled-components';

import analytics from '../helpers/analytics';

import LinkButton from './LinkButton';

import illumeIcon from '../icons/illume-icon.svg';

import { HOME_LINK, SIGN_IN_LINK, START_NOW_LINK } from '../constants/links';
import { getMainAppFullPath } from '../constants/getUrl';

const TRACK_LOGIN = 'login - marketing';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
  z-index: 3;
  position: absolute;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 80px;
    padding: 8px 16px;
    width: 100%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 96px;
    padding: 16px 24px;
    width: 100%;
  }
`;

const Home = styled.a`
  padding: 8px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0;
  }
`;

const HomeImage = styled.img`
  width: 65px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 8px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 8px 0;
  }
`;

const Actions = styled.div`
  display: flex;
  padding: 16px 0;
`;

const CreateLink = styled(LinkButton)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  max-width: 352px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    align-self: center;
    font-size: 18px;
    line-height: 25.74px;
    width: 214px;
  }
`;

const Login = styled(LinkButton)`
  background: transparent;
  color: ${(props) => props.theme.colors.blackText};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 7px 0 7px 14px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    align-self: center;
    font-size: 18px;
    line-height: 25.74px;
  }
`;

const Header = () => {
  const handleClickLogin = () => {
    analytics.track(TRACK_LOGIN);
  };

  return (
    <Layout>
      <Home href={'/'}>
        <HomeImage alt='' src={illumeIcon} />
      </Home>
      <Actions>
        <CreateLink href={START_NOW_LINK}>Create your group gift</CreateLink>
        <Login href={SIGN_IN_LINK} onClick={handleClickLogin}>
          Login
        </Login>
      </Actions>
    </Layout>
  );
};

export default Header;
