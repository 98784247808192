import { useEffect, useState } from 'react';
import { theme as themeStyles } from '../styles/theme';

const useFormFactors = () => {
  const [mobile, setMobile] = useState<boolean>(() => {
    if (typeof window === 'undefined') return false;

    return window.innerWidth < themeStyles.breakpointsNumbers.tablet;
  });
  const [tablet, setTablet] = useState<boolean>(false);
  const [laptop, setLaptop] = useState<boolean>(false);
  const [laptopAll, setLaptopAll] = useState<boolean>(false);

  const updateFormFactors = () => {
    setMobile(window.innerWidth < themeStyles.breakpointsNumbers.tablet);
    setTablet(
      window.innerWidth >= themeStyles.breakpointsNumbers.tablet &&
        window.innerWidth < themeStyles.breakpointsNumbers.laptop
    );
    setLaptop(
      window.innerWidth >= themeStyles.breakpointsNumbers.laptop &&
        window.innerWidth < themeStyles.breakpointsNumbers.laptopL
    );
    setLaptopAll(
      window.innerWidth >= themeStyles.breakpointsNumbers.laptopL &&
        window.innerWidth < themeStyles.breakpointsNumbers.UHD
    );
  };

  useEffect(() => {
    updateFormFactors();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateFormFactors);
    return () => window.removeEventListener('resize', updateFormFactors);
  }, []);

  return {
    mobile: mobile,
    tablet: tablet,
    laptop: laptop,
    laptopAll: laptopAll,
  };
};

export default useFormFactors;
