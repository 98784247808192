import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { POSTS_LINK } from '../constants/links';
import MetaTags from '../components/MetaTags';
import Header from '../components/HeaderBlog';
import Container from '../components/Container';
import { theme } from '../styles/theme';
import arrow from '../icons/arrow.svg';
import { handleScroll } from '../helpers/scrollTop';
import data from '../data/FooterMainLinks';
import Footer from '../sections/common/Footer';

const Layout = styled.section`
  display: flex;
  padding: 80px 10% 200px;
  min-height: 100vh;
  min-width: 100%;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow-x: hidden;
    padding: 150px 50px 250px;
  }
`;

const ContainerEnhanced = styled(Container)`
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  .image-container {
    @media ${(props) => props.theme.breakpoints.laptop} {
      margin-left: auto;
      margin-right: auto;
      width: 70%;
    }
  }

  .image-container-vertical {
    @media ${(props) => props.theme.breakpoints.laptop} {
      display: flex;
      justify-content: center;
    }
  }

  img {
    max-height: 500px;
    max-width: 100%;
  }

  sup {
    font-size: 12px;
    line-height: 1.25;

    @media ${(props) => props.theme.breakpoints.laptop} {
      font-size: 16px;
    }
  }
`;

const Section = styled.div`
  display: flex;
  text-align: left;
  min-width: 900;
  align-self: flex-start;

  @media ${(props) => props.theme.breakpoints.laptop} {
    min-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};
    align-self: auto;
  }
`;

const Text = styled.h1`
  font-size: 16px;
  line-height: 1.55;
  color: ${(props) => props.theme.colors.blackText};
  text-align: left;
  font-weight: 600;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 30px;
  }
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 30px;
    height: 36px;
  }
`;

const Link = styled.a`
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const TitleText: FC<{ title: string }> = ({ title }) => {
  return (
    <Section>
      <Link href={POSTS_LINK}>
        <Arrow src={arrow} />
      </Link>
      <Text>{title}</Text>
    </Section>
  );
};

const Template: FC<{
  data: {
    markdownRemark: {
      frontmatter: {
        slug: string;
        title: string;
        description?: string;
        ogTitle?: string;
        ogDescription?: string;
        ogImage?: string;
      };
      html: string;
    };
  };
}> = ({
  data: {
    markdownRemark: {
      frontmatter: { slug, title, description, ogTitle, ogDescription, ogImage },
      html,
    },
  },
}) => {
  const [scrolled, setScrolled] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    changeBackground();
    handleScroll();
    window.addEventListener('scroll', changeBackground);
  });

  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        slug={slug}
        pretitle='Blog'
        title='illume'
        ogTitle={ogTitle}
        description={description}
        ogDescription={ogDescription}
        ogImage={ogImage}
        ogType='article'
      />
      <Header />
      <Layout>
        <TitleText title={title} />
        <ContainerEnhanced dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
      <Footer links={data} isBlog={true} />
    </ThemeProvider>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        description
        ogTitle
        ogDescription
        ogImage
      }
    }
  }
`;

export default Template;
