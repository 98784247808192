import React, { FC, useMemo } from 'react';
import useFormFactors from '../../helpers/useFormFactors';
import { Copyright, CopyrightAndNavLinks, Layout, LinkContainer, Links } from './FooterStyles';
import { Link } from './types';

interface Props {
  links: Link[];
  isBlog?: boolean;
}
const Footer: FC<Props> = ({ links, isBlog }) => {
  const formFactors = useFormFactors();
  const year: number = new Date().getFullYear();

  const usableLinks = useMemo(() => {
    return formFactors.laptopAll
      ? links.sort((a, b) => a.index - b.index)
      : links.sort((a, b) => a.mobileIndex - b.mobileIndex);
  }, []);

  return (
    <Layout isBlog={isBlog}>
      <Links>
        {usableLinks.map((link, index) => (
          <LinkContainer key={index}>
            {link.icon ? <img src={link.icon} /> : null}
            <a href={link.link}>{link.label}</a>
          </LinkContainer>
        ))}
      </Links>
      <CopyrightAndNavLinks>
        <Copyright>© {year}. All rights reserved. Dedicated to those who shine.</Copyright>
      </CopyrightAndNavLinks>
    </Layout>
  );
};

export default Footer;
