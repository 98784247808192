
import { Link } from '../types/link';

import spotify from '../icons/spotify.svg';

import {
  FAQS_LINK,
  CONTACT_US_LINK,
  MERCHANT_INTEREST_LINK,
  POSTS_LINK,
  ABOUT_LINK,
  TEAMS_LINK,
  PLAYLIST_LINK,
} from '../constants/links';

const links: Link[] = [
  { label: 'FAQs', link: FAQS_LINK, index: 0, mobileIndex: 0 },
  { label: 'Contact Us', link: CONTACT_US_LINK, index: 1, mobileIndex: 4 },
  { label: 'Merchants', link: MERCHANT_INTEREST_LINK, index: 2, mobileIndex: 1 },
  { label: 'Blog', link: POSTS_LINK, index: 3, mobileIndex: 5 },
  { label: 'About', link: ABOUT_LINK, index: 4, mobileIndex: 2 },
  { label: 'Teams', link: TEAMS_LINK, index: 5, mobileIndex: 6 },
  { label: 'Our Playlist', link: PLAYLIST_LINK, icon: spotify, index: 6, mobileIndex: 3 }
];

export default links;